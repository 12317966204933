import React, { useEffect, Fragment } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import GraphQLErrorList from "../components/GraphQLErrorList";
import CTA from "../components/CTA";
import componentMapper from "../components/componentMapper";
import { getFluidProps } from "../utils/image-url";

export const query = graphql`
  query HowItWorksspagesQuery {
    page: sanityPage(slug: { current: { eq: "how_it_works" } }) {
      ...PageInfo
    }
    settings: sanitySiteSettings {
      title
      id
      facebook
      instagram
      twitter
      _rawOpenGraph(resolveReferences: { maxDepth: 10 })
      _rawLogo(resolveReferences: { maxDepth: 10 })
    }
    footer: sanitySiteFooter {
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawLinks(resolveReferences: { maxDepth: 10 })
      _rawSlug(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const ICONS = {
  gather: 'M52.3 54.7L26.1 57l-.2-1.9 12.4-1-3.2-37-.2.1-11 11.2-1.5-1.3 11.2-11.4 3.4-.3L40.4 54l11.7-1z',
  follow: 'M51 52.9l.1 2L25.7 57l-.2-2.1 13.7-16.2c3.5-4.1 5.6-8.3 5.3-12.4l-.2-1.5c-.4-5-3.9-8.5-10-8-5.7.6-8.4 4-9.5 8.7l-2-.4c1-5.3 4.5-9.5 11.5-10.2 7.3-.6 11.7 3.4 12.3 10.4.5 5.4-1.8 9.9-6 14.7L28 55l23-2.1z',
  convos: 'M29.6 34.3l5.2-.4c5.8-.5 9.2-4.2 8.8-8.9v-1.2c-.5-4.7-4.5-7.5-9.7-7-5.1.4-7.8 2.9-9.2 6.4l-1.7-.9c1.4-3.8 4.8-7 10.7-7.4 6.6-.6 11.6 2.9 12 9 .6 5.6-2.5 9-7 10.4v.2c5.2.3 9.2 3.5 9.7 9.5.6 7-3.8 12-11.4 12.6-5.9.5-10-1.4-13-5.3l1.5-1.3c2.7 3.6 6.2 5.2 11.3 4.7 6.2-.5 9.8-4.4 9.4-9.8l-.1-1.3c-.5-5.3-4.6-8.5-11-7.9l-5.3.5-.2-1.9z'
}

export default ({ data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const { _rawContent, _rawOpenGraph } = data.page;

  const children = _rawContent.map((i, idx) => {
    if (idx === 0) {
      return (
        <div className="row" key={i._key}>
          <div className="col-xs-18 col-sm-10 col-md-9 col-md-offset-1 col-lg-9 align-justify">
            <h1>{i.heading}</h1>
            <p>
              <CTA {...i.cta} size="xl" />
            </p>
          </div>
          <div className="col-xs-18 col-sm-8">
            <div className="shape shape-right">
              <div>
                <Img
                  fluid={getFluidProps(i.illustration.image.asset)}
                  alt={i.illustration.image.caption}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (i._type === "custom") {
      if (i.widget_id === "points") {
        return i.content.map((section) => {
          return (
            <div key={section._key}>
              <div className="row">
                <div className="col-xs-14 col-sm-14 col-md-10 col-md-offset-4">
                  <h2
                    className="no-margin"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {section.heading.text}
                  </h2>
                  <p className="no-margin">{section.subheading}</p>
                </div>
              </div>
            </div>
          );
        });
      } else if (i.widget_id === "gather" || i.widget_id === "convos" || i.widget_id === 'follow') {
        const [heading, ...other] = i.content;
        const icon = ICONS[i.widget_id];
        return (
          <div key={i._key}>
            <div
              className="shape-background shape-background-dots shape-background-left"
              id={i.widget_id}
            >
              <div className="row">
                <div className="col col-sm-offset-1">
                  <span className="hiw-step">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 74">
                      <g fill="none" fillRule="evenodd">
                        <path
                          fill="#173659"
                          d="M21.5 70.6c17 7 35.8 2.9 45-12 16-26-2.1-51.4-12-55-19-7-42-5-51 18s.2 41.6 18 49z"
                        />
                        <path
                          fill="#FFF"
                          d={icon}
                        />
                      </g>
                    </svg>
                  </span>
                  <h2 className="xxl" style={{ whiteSpace: "pre-wrap" }}>
                    {heading.content}
                  </h2>
                </div>
              </div>
              {other.map((widget) => {
                const Component = componentMapper(widget._type);
                return <Component key={widget._key} data={widget} />;
              })}
            </div>
          </div>
        );
      }
    } else if (i._type === "embedsocial") {
      return (
        <div className="row" key={i._key}>
          <div className="col">
            <div className="embedsocial-reviews">
              <iframe
                src={`https://embedsocial.com/api/reviews/widget/${i.widget_id}`}
                scrolling="no"
                className="embedsocial-reviews-iframe"
                title="Facebook and Google reviews by EmbedSocial"
                style={{
                  width: "100%",
                  height: i.height,
                  border: 0,
                  overflow: "hidden",
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    const Component = componentMapper(i._type);
    return <Component data={i} key={i._key} />;
  });

  return (
    <Layout
      title={_rawOpenGraph?.title}
      description={_rawOpenGraph?.description}
      settings={data.settings} footer={data.footer}>
      {children}
    </Layout>
  );
};
